/* global ajax_object */

$(document).on('click', '.pagination', function () {
  const container = $(this).parent().prev('.erfolgsgeschichten-posts');
  const pagWrapper = $(this).closest('.pagination-wrapper');
  let currentPage = pagWrapper.attr('data-current-page');

  if ($(this).hasClass('pagination-prev')) {
    currentPage--;
  } else if ($(this).hasClass('pagination-next')) {
    currentPage++;
  }

  $.ajax({
    url: ajax_object.ajax_url,
    type: 'POST',
    data: {
      action: 'get_success_posts',
      nonce: ajax_object.nonce,
      currentPage,
    },

    success: function (data) {
      container.html(data.html);

      const containerElem = container.get(0);
      const rect = containerElem.getBoundingClientRect();
      if (rect.top < 0 || rect.bottom > window.innerHeight) {
        containerElem.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }

      if (data.is_first) {
        $('.pagination-prev').find('span').addClass('disabled');
        $('.pagination-prev').addClass('btn-disabled');

        $('.pagination-next').find('span').removeClass('disabled');
        $('.pagination-next').removeClass('btn-disabled');
      }

      if (data.is_last) {
        $('.pagination-next').find('span').addClass('disabled');
        $('.pagination-next').addClass('btn-disabled');

        $('.pagination-prev').find('span').removeClass('disabled');
        $('.pagination-prev').removeClass('btn-disabled');
      }
      pagWrapper.attr('data-current-page', currentPage);
    },
  });
});
